import React, {useEffect, useState} from "react";
import axios from "axios";
import cheerio from "cheerio";

export default (props: any) => {

    const [importStatus, setImportStatus] = useState<'sleeping' | 'pending' | 'done' | 'error'>('sleeping');
    const [mission, setMission] = useState('');
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [note, setNote] = useState('')

    function importCurrentProfile() {
        setImportStatus('pending');
        window?.top?.postMessage({type: "getFullProfile", data: null}, '*');
    }

    /**
     * Recevoir les callbacks des actions et envoyer la requête au serveur
     */
    window.onmessage = function (e) {

        if (e.data && e.data.type === 'setProfileToken') {
            if(e.data.data.linkedinToken !== props.profileInfos.linkedinToken) {
                props.setProfileInfos(e.data.data ?? '');
            }
        }

        if (e.data && e.data.type === 'getFullProfileCB') {
            (async () => {
                try {

                    const pageRaw = e.data?.data?.profile;

                    if (!pageRaw) {
                        return alert('Erreur, page vide');
                    }
                    let $page = await cheerio.load(pageRaw);
                    let account=($page('img[class*="global-nav__me-photo"]').attr('alt')?? "").trim();


                    const {
                        // @ts-ignore
                        status,
                        // @ts-ignore
                        data
                    } = (await axios.post(`${process.env.REACT_APP_GATEWAY}/v3/addons2/communities/linkedin/importUserToKanban`, {
                        profile: e.data.data.profile,
                        profileContact: e.data.data.profileContact,
                        linkedinToken: props.profileInfos.linkedinToken,
                        linkedin: props.profileInfos.linkedin,
                        missionId: mission,
                        email: email,
                        phone: phone,
                        note: note,
                        account:account,
                    }, {
                        headers: {
                            "content-type": "application/json"
                        }
                    })).data;
                    if (status === 'ok') {
                        setImportStatus('done');
                    } else {
                        setImportStatus('error');
                    }
                } catch (error) {
                    // @ts-ignore
                    alert(error.message);
                }
            })();
        }

        if (e.data && e.data.type === 'updateCoordinates') {

            (async() => {

                if(!e.data.data.page) {
                    return;
                }

                let $page = await cheerio.load(e.data.data.page);

                const email = ($page('section[class*="ci-email"] a')?.text() ?? "").trim();
                const phone = ($page('section[class*="ci-phone"] a')?.text() ?? "").trim();

                if(email) {
                    setEmail(email);
                }

                if(phone) {
                    setPhone(phone);
                }

            })();

        }
    }

    /**
     * Demander à la page parent (LinkedIn) de regarder si le champ email / telephone est disponible
     */
    useEffect(() => {
        window?.top?.postMessage({type: "getProfileCoordinates", data: null}, '*');
    }, [])

    return <>
        Choisir un Kanban vers lequel importer ce candidat:
        <br/>
        <select style={{
            color: "black"
        }} value={mission} onChange={(e) => setMission(e.target.value)}>
            <option value={''}>-- Choisir un Kanban --</option>
            {props.kanbans?.sort((a: any, b: any) => {
                if (a.positionWanted < b.positionWanted) return -1
                return 1
            }).map((kanban: any) => <option style={{color: "black"}} value={kanban.missionId}>
                {kanban.positionWanted} (ref: {kanban.missionRef}, n: {kanban.missionNumberOfCandidacies})
            </option>)}
        </select>

        <br/><br/>

        Email: <input style={{color: "black"}} value={email} onChange={(e) => setEmail(e.target.value)}/>
        Téléphone: <input style={{color: "black"}} value={phone} onChange={(e) => setPhone(e.target.value)}/>

        <br/>

        Notes:
        <br/>
        <textarea style={{
            width: '500px',
            height: '130px',
            color: 'black'
        }} value={note} onChange={(e) => setNote(e.target.value)}>

        </textarea>

        <br/><br/>

        <button disabled={importStatus !== 'sleeping' || !mission} style={{color: "black"}}
                onClick={() => importCurrentProfile()}>
            Importer dans le Kanban
        </button>

        {
            importStatus === 'done' && <span>
              Succès de l'import
            </span>
        }

        {
            importStatus === 'error' && <span>
              Une erreur est survenue
            </span>
        }

    </>
}