import React, {useEffect, useState} from "react";
import axios from "axios";

const cheerio = require('cheerio');

export default (props: any) => {

    const [id, setId] = useState("");
    return (<div style={{
        textAlign: 'left'
    }}>

        {process.env.REACT_APP_GATEWAY === "http://localhost:7777" && <>LOCAL <br/></>}

        Entrez votre id pour vous connecter!
        <br/><br/>


        Id: <input style={{color: "black"}} value={id} onChange={(e) => setId(e.target.value)}/>

        <button style={{color: "black"}} onClick={async () => {
            const {
                // @ts-ignore
                status,
                // @ts-ignore
                data
            } = (await axios.post(`${process.env.REACT_APP_GATEWAY}/v3/addons2/communities/linkedin/login`,
                {
                    id: id,
                },
                {
                    headers: {"content-type": "application/json"}
                })).data;


            if(status==="loggedIn"){
                window.localStorage.setItem('token', id);
                props.setTokenExists(true);
                props.setGoodToken(true);
                props.appContext.setDidLoginSucceed(true);
            } else {
                alert('Id refusé')
            }


        }}>Connexion</button>

            <br/><br/>

            </div>)

        }