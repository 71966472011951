function extractPhoneFromString(args:{input:string}){

    let input=args.input.split(" ").join("").split(".").join("").split("-").join("");
    const phone13=(input.match(/[0-9]{13}/g) ?? [])[0];
    if (phone13) return phone13;
    const phone11=(input.match(/[0-9]{11}/g) ?? [])[0];
    if (phone11) return "+"+phone11;
    const phone10=(input.match(/[0-9]{10}/g) ?? [])[0];
    if(phone10) return phone10
    return "";
}
export default extractPhoneFromString;


// console.log(1,extractPhoneFromString({input:"0987654321"}) || "failed") ;
// console.log(2,extractPhoneFromString({input:"09 87 65 43 21"}) || "failed");
// console.log(3,extractPhoneFromString({input:"09.87.65.43.21"}) || "failed") ;
// console.log(4,extractPhoneFromString({input:"09-87-65-43-21"}) || "failed");
// console.log(5,extractPhoneFromString({input:"+33987654321"}) || "failed");
// console.log(6,extractPhoneFromString({input:"+339 87 65 43 21"}) || "failed");
// console.log(7,extractPhoneFromString({input:"Voici mon Num 0987654321"}) || "failed") ;
// console.log(8,extractPhoneFromString({input:"Voici mon Num 09 87 65 43 21"}) || "failed");
// console.log(9,extractPhoneFromString({input:"Voici mon Num 09.87.65.43.21"}) || "failed") ;
// console.log(10,extractPhoneFromString({input:"Voici mon Num 09-87-65-43-21"}) || "failed");
// console.log(11,extractPhoneFromString({input:"Voici mon Num +33987654321"}) || "failed");
