import React, {useEffect, useState} from "react";
import axios from "axios";
const cheerio = require('cheerio');

export default (props: any) =>  {

    const [candidatesToParseMail, setCandidatesToParseMail] = useState([]);
    const [linkedinCookie, setLinkedinCookie] = useState("");
    /**
     * Receive callbacks from actions
     */

    window.onmessage = async function (e) {

        if (e.data && e.data.type === 'getAccount') {

            const pageRaw = e.data?.data?.page;
            if (!pageRaw) {
                return alert('Erreur, page vide');
            }
            let $page = await cheerio.load(pageRaw);
            let account = ($page('img[class*="global-nav__me-photo"]').attr('alt') ?? "").trim();
            const {
                // @ts-ignore
                status,
                // @ts-ignore
                data
            } = (await axios.post(`${process.env.REACT_APP_GATEWAY}/v3/addons2/communities/linkedin/importCandidatesToParseMail`, {
                account
            }, {
                headers: {
                    "content-type": "application/json"
                }
            })).data;
            setCandidatesToParseMail(data);
        }
    }

    useEffect(() => {
        window?.top?.postMessage({type: "getLinkedinAccount", data: null}, '*');
    }, [])


    return (<div style={{
        textAlign: 'center'
    }}>

        {process.env.REACT_APP_GATEWAY === "http://localhost:7777" && <>LOCAL <br/></>}

        <br/>
        <br/>

        Parser les profils pour récupérer les mails
        Vous avez {candidatesToParseMail?.length ?? 0} candidat(s) en attente

        <br/><br/>

        Rentrer vos cookies linkedin

        <textarea  style={{color: 'black', width:"200px", height:"200px"}} value={linkedinCookie} onChange={(e)=>{
            setLinkedinCookie(e.target.value);
        }}> </textarea>

        <button style={{
            color: 'white', borderRadius: "20px", borderWidth: "0px", fontSize: "15px",
            paddingTop: "5px", paddingBottom: "5px", fontWeight: "bold", backgroundColor: "#44C5F3"
        }}
            onClick={async () => {
                 // window?.top?.postMessage({type: "parseMailOfCandidates", data: {candidatesToParseMail: candidatesToParseMail}}, '*');
                const {
                    // @ts-ignore
                    status,
                    // @ts-ignore
                    data
                } = (await axios.post(`${process.env.REACT_APP_GATEWAY}/v3/addons2/communities/linkedin/parseMailToCandidates`, {
                    linkedinCookie:linkedinCookie,
                    candidatesToParseMail:candidatesToParseMail,
                }, {
                    headers: {
                        "content-type": "application/json"
                    }
                })).data;

        }}>
            Lancer l'import

        </button>

        <br/><br/>

    </div>)

}