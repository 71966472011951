import React from "react";

export default (props: any) => {

    if(!props.profileBB?.doesUserExist) {
        return <>La personne n'est pas sur BB</>
    }

    return <>
        La personne est sur BB

        <section style={{
            margin: '20px 0'
        }}>
            Finder:
            <ul>
                {
                    props.profileBB?.kanbans?.map((kanban:any)=>(
                        <a href={`https://myshortlist.co/business/mission/${kanban.missionId}`} target={'_blank'}>
                            <li>Kanban: "{kanban.positionWanted}", colonne: "{kanban.columnName}", client: "{kanban.clientName}"</li>
                        </a>))
                }
                {props.profileBB?.kanbans?.length === 0 && <div>La personne ne se trouve dans aucun kanban</div>}
            </ul>
        </section>



    </>
}