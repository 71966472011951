import React from "react";

export default (props: any) => {
    return <div style={{
        margin: "15px 0"
    }}>
        <button style={{color: "black"}} onClick={() => props.setPage('profile')}>
            Profil BB
        </button>
        <button style={{color: "black"}} onClick={() => props.setPage('import')}>
            Import
        </button>
    </div>
}