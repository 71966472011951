import React, {useEffect, useState} from "react";
import axios from "axios";
import extractPhoneFromString from "../helpers/extractPhoneFromString";
import extractMailFromString from "../helpers/extractMailFromString";
import * as http from "http";
const cheerio = require('cheerio');



export default (props: any) => {

    const [emailFromCoordinates, setEmailFromCoordinates] = useState('');
    const [phoneFromCoordinates, setPhoneFromCoordinates] = useState('');
    /**
     * Receive callbacks from actions
     */


    window.onmessage = async function (e) {

        if (e.data && e.data.type === 'parseMessageCB') {

            const pageRaw = e.data?.data?.page;
            const coordinates = e.data?.data?.coordinates ?? "";

            if (!pageRaw) {
                return alert('Erreur, page vide');
            }

            let $page = await cheerio.load(pageRaw);
            // let $coordinates = await cheerio.load(coordinates);
            // const emailFromCoordinates = ($coordinates('section[class*="ci-email"] a')?.text() ?? "").trim();
            // const phoneFromCoordinates = ($coordinates('section[class*="ci-phone"] a')?.text() ?? "").trim();
            // alert("emailFromCoordinates" + emailFromCoordinates);
            let fullName = ($page('h2[class*="msg-entity-lockup__entity-title"]').text() ?? "").trim();
            let linkedin = "https://www.linkedin.com" + ($page('a[class*="msg-thread__link-to-profile"]').attr('href') ?? "").trim();
            let avatarUrl = ($page('img[class*="EntityPhoto-circle-5"]').attr('src') ?? "");
            let discussionRaw = $page('div[class*="msg-s-message-list"] li[class*="msg-s-message-list__event"]'); // todo: checker si ça marche
            let discussion: any[] = [];
            let account = ($page('img[class*="global-nav__me-photo"]').attr('alt') ?? "").trim();

            for (let item of discussionRaw) {
                let $item = cheerio.load(item);
                discussion.push({
                    from: ($item('span[class*="msg-s-message-group__name"]').text() ?? "").trim(),
                    message: ($item('p[class*="msg-s-event-listitem__body"]').text() ?? "").trim(),
                });
            }

            console.log("discussion", discussion)

            let ref = "";
            let phoneHelp="";
            let phone = "";
            let mailHelp="";
            let mail = "";

            for (let item of discussion) {
                if (item.message?.includes('ref#')) {
                    let bag = item.message.split(' ');
                    for (let word of bag) {
                        if (word.includes('ref#')) {
                            ref = ((word.split('ref#') ?? [])[1]).replace(')', '');
                        }
                    }
                } else if (item.message?.includes('refhashtag#')) {
                    let bag = item.message.split(' ');
                    for (let word of bag) {
                        if (word.includes('refhashtag#')) {
                            ref = ((word.split('refhashtag#') ?? [])[1]).replace(')', '');
                        }
                    }
                }
            }
            for (let item of discussion) {
                phoneHelp=extractPhoneFromString({input:item.message }) ;
                if(phoneHelp!==""){
                    phone=phoneHelp;
                }
                mailHelp=extractMailFromString({input:item.message});
                 if(mailHelp!=="" ) {
                     mail=mailHelp;
                 }

            }
            // if(emailFromCoordinates !==""){
            //     mail=emailFromCoordinates;
            // }

            // Afficher la discussion
            const strte = (() => {
                let di = linkedin + '\n'
                for(let d of discussion) {
                    di += (d.from) + '\n';
                    di+= d.message + '\n\n'
                }
                return di
            })()
            // alert(strte);

            if (!ref) {
                alert("PAS DE REF");
                return;
            }

            let discussionEntity: any = {
                fullName,
                linkedin,
                avatarUrl,
                discussion,
                ref,
                account,
                phone,
                mail
            }

            // // Save the person
            // // @ts-ignore
            // alert("importUserToKanbanFromMessage");
            const {
                // @ts-ignore
                status,
                // @ts-ignore
                data
            } = (await axios.post(`${process.env.REACT_APP_GATEWAY}/v3/addons2/communities/linkedin/importUserToKanbanFromMessage`, {
                discussionEntity
            }, {
                headers: {
                    "content-type": "application/json"
                }
            })).data;

            discussionEntity = {};
            fullName = '';
            linkedin = '';
            avatarUrl = '';
            discussion = [];
            ref = '';
        }

    }

     const link=<a href={"https://black-belt.typeform.com/to/jFdVs4At#missionref={{ref}}"}> ce lien </a>


    const messages = [
        // {
        //     name: 'No Go',
        //     message: 'C\'est noté, merci {{firstName}} de votre retour. ' +
        //         'Pouvez-vous me recommander une personne susceptible de correspondre à cette opportunité ?'
        // },
        {
            name: 'Go',
            message: 'Merci  {{firstName}} de votre retour, j\'essaye de vous contacter dès que possible'
        },
        ,
        {
            name: 'Go sans numéro',
            message: 'Parfait  {{firstName}} ! A quel numéro puis-je vous contacter ?'
        }
        ,
        {
            name: '+ d\'info',
            message: 'Merci  {{firstName}} de votre retour, je vous propose d\'en discuter de vive voix (plus facile :) ' +
                'A quel numéro puis-je vous contacter ?'
        }
        ,
        {
            name: 'Pourvu ',
            message: 'Merci  {{firstName}} de votre retour. Malheureusement, le poste a été pourvu. Restons en contact pour d\'autres opportunités ;)'
        },
        {
            name: 'Doublon',
            message: 'Effectivement, je n\'avais pas connaissance que vous étiez déjà en contact avec nous ! Cette opportunité vous intéresse-t-elle ?'
        },
        // {
        //     name:'Recommandation ',
        //     message:'Merci à vous, je vais jeter un œil à son profil. Belle journée !'
        // },
        {
            name:'Cooptation',
            message:`cooptation`
        },
        {
            name: "Interessé",
            message: "Bonjour {{firstName}},\n\n" +
                "Merci de votre retour.\n" +
                "\n" +
                "Ma collègue va prendre contact avec vous afin de vous proposer un créneau pour échanger davantage sur vos besoins et comprendre vos attentes :)\n" +
                "\n" +
                "A très vite"
        },
        {
            name: "Pas intéressé",
            message: "Merci {{firstName}} de votre retour. Au plaisir d'échanger et de vous accompagner si la situation venait à évoluer. A bientôt"
        },
        {
            name: "A retirer de la liste:",
            message: "C'est noté. Gardez mon contact si vous changez d'avis ;)\n" +
                "\n" +
                "A bientôt"
        }

    ];


    const [message, setMessage] = useState("");



    return (<div style={{
        textAlign: 'center'
    }}>

        {process.env.REACT_APP_GATEWAY === "http://localhost:7777" && <>LOCAL <br/></>}

        Parser la discussion affichée ou les discussions chargées et les importer dans la bonne mission à l'aide du mot
        clé '#ref'

        <br/><br/>

        <button style={{
            color: 'white', borderRadius: "20px", borderWidth: "0px", fontSize: "15px",
            paddingTop: "5px", paddingBottom: "5px", fontWeight: "bold", backgroundColor: "#44C5F3"
        }}
                onClick={() => {
                    window?.top?.postMessage({type: "parseAllMessages", data: null}, '*');
                }}>
            Importer les discsussions scrollées

        </button>

        <br/><br/>

        <button style={{color: 'black', borderRadius: "30px", borderWidth: "0px", fontSize: "11px"}}
                onClick={() => {
                    window?.top?.postMessage({type: "parseOneMessage", data: null}, '*');
                }}>
            Importer la discussion affichée
        </button>

        <br/><br/>


        <br/>

        <hr/>

        <br/>

        Redaction automatique de messages

        <br/><br/>

        <select style={{color: "black", borderRadius: "20px", borderWidth: "0px", fontSize: "16px"}} value={message}
                onChange={(e) => {
                    setMessage(e.target.value);
                }}>
            <option style={{backgroundColor: 'black'}} value={""}>---</option>
            {messages.map((item: any, key: number) => (
                <option style={{backgroundColor: 'black'}} value={`${item.message}`}>
                    {item.name}
                </option>))}
        </select>

        <br/><br/>

        <button disabled={!message} style={{
            color: 'black', borderRadius: "20px", borderWidth: "0px", fontSize: "16px",
            backgroundColor: "white"
        }}
                onClick={() => {
                    window?.top?.postMessage({type: "writeMessage", data: {message: message}}, '*');
                }}> Importer le message
        </button>
        <br/>
        <br/>
    </div>)

}
