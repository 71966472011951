import {useEffect, useReducer, useState} from "react";
import InProfile from "./componentsInProfile/InProfile";
import InImport from "./componentsInProfile/InImport";
import InProfileHeader from "./componentsInProfile/InProfileHeader";
import InProfileNavbar from "./componentsInProfile/InProfileNavbar";
import reducerProfileBB from "./componentsInProfile/reducerProfileBB";
import axios from "axios";

export default (props: any) => {

    const [page, setPage] = useState<'profile' | 'import'>('profile');
    const [profileInfos, setProfileInfos] = useState({
        linkedinToken: '',
        linkedin: ''
    });
    const [loading, setLoading] = useState(false);
    const [profileBB, dispatchProfileBB] = useReducer(reducerProfileBB, {
        doesUserExist: false
    });
    const [kanbans, setKanbans] = useState([]);

    /**
     * Recevoir les callbacks des actions
     * => dans les sous pages il faut egalement intégrer setProfileToken
     */
    window.onmessage = function (e) {
        if (e.data && e.data.type === 'setProfileToken') {
            if (e.data.data.linkedinToken !== profileInfos.linkedinToken) {
                setProfileInfos(e.data.data ?? '');
            }
        }
    }

    /**
     * Fetch the profile for each new user profile
     */
    useEffect(() => {
        if(profileInfos.linkedin) {
            (async () => {
                try {
                    setLoading(true);
                    const {status, data} = (await axios.get(`${process.env.REACT_APP_GATEWAY}/v3/addons2/communities/linkedin/userProfile`, {
                        headers: {
                            token: profileInfos.linkedinToken,
                            linkedin: profileInfos.linkedin
                        }
                    })).data;
                    setLoading(false);
                    if(status === 'ok') {
                        dispatchProfileBB({
                            type: 'SET_PROFILE',
                            data: data
                        });
                    }
                } catch(error) {
                    setLoading(false)
                    // @ts-ignore
                    alert(error.message);
                }
            })()
        }
    }, [profileInfos.linkedinToken, props.appContext.displayLoginPage]);

    /**
     * Fetch the kanbans
     */
    useEffect(() => {
        (async() => {
           try {
               const {status, data} = (await axios.get(`${process.env.REACT_APP_GATEWAY}/v3/addons2/communities/linkedin/kanbans`, {})).data;
               if(status === 'ok') {
                   setKanbans(data);
               }
           } catch(error) {
               // @ts-ignore
               alert(error.message)
           }
        })()
    }, []);

    const inProfileContext = {
        page, setPage,
        profileInfos, setProfileInfos,
        loading, setLoading,
        profileBB, dispatchProfileBB,
        kanbans, setKanbans
    }

    return(<>

        <InProfileHeader {...inProfileContext} />

        <InProfileNavbar {...inProfileContext}/>

        <InProfileSwitch {...inProfileContext} />

    </>);
}

function InProfileSwitch(props: any) {

    if(props.loading) {
        return <>Chargement ...</>
    }

    switch(props.page) {

        case 'profile':
            return <InProfile {...props} />

        case 'import':
            return <InImport {...props} />

        default:
            return <>Erreur de chargement</>

    }
}