export default(ps: any, action: {type: string, data: any}) => {

    switch(action.type) {

        case 'SET_PROFILE':
            return {...action.data}

        default:
            return ps

    }
}