function extractMailFromString(args:{input:string}){

    const mail=(args.input.match(/(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g) ?? [])[0];
    return mail ?? "";
}
export default extractMailFromString;

// console.log(1, extractMailFromString({input:"mail@gmail.com"}) || "failed");
// console.log(2, extractMailFromString({input:"mailgmail.com"}) || "failed");
// console.log(3, extractMailFromString({input:"mail@gmailcom"} )|| "failed");
// console.log(4, extractMailFromString({input:"ceci est mon mail mail@gmail.com merci"} )|| "failed");
