import React, {useEffect, useState} from 'react';
import './styles/main.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import LinkedInBlockMessenger from "./components/LinkedInBlockMessenger";
import LinkedInBlockProfile from "./components/LinkedInBlockProfile";
import LoginPage from "./components/LoginPage";
import axios from "axios";
import LinkedInBlock from "./components/componentsInProfile/LinkedInBlock";

export default (props: any) => {

    // window.localStorage.setItem('token', 'aaaaa');
    // window.localStorage.getItem('token');
    // localStorage.removeItem("token");
    //5ea7def5ae03fc0017bb4d8f

    const [isPageReady, setIsPageReady] = useState(false);
    const [didLoginSucceed, setDidLoginSucceed] = useState(false);
    const [tokenExists, setTokenExists] = useState<boolean>(false);
    const [goodToken, setGoodToken] = useState<boolean>(false);

    /**
     * See if user is logged in
     */
   useEffect(() => {
        (async () => {
            try {
                const token = window.localStorage.getItem('token');

                if (token) {
                    setTokenExists(true);
                    const {
                        // @ts-ignore
                        status,
                        // @ts-ignore
                        data
                    } = (await axios.post(`${process.env.REACT_APP_GATEWAY}/v3/addons2/communities/linkedin/login`,
                        {
                            id: token,
                        },
                        {
                            headers: {"content-type": "application/json"}
                        })).data;
                    if (status === "loggedIn") {
                        setGoodToken(true);
                    }
                } else {
                    setTokenExists(false);
                }
                setIsPageReady(true);
            } catch (error) {
                // @ts-ignore
                alert(error.message);
                setIsPageReady(true);
            }
        })()
    }, []);

    const displayLoginPage = (!tokenExists || (tokenExists && !goodToken));

    const appContext = {
        displayLoginPage,
        setDidLoginSucceed
    }

    if (!isPageReady) {
        return (<></>);
    }

    if(didLoginSucceed) {
        return (<>Identification réussie ! Rechargez la page courrante !</>);
    }

    if (displayLoginPage) {
        return <LoginPage appContext={appContext}
                          tokenExists={tokenExists}
                          setTokenExists={setTokenExists}
                          goodToken={goodToken}
                          setGoodToken={setGoodToken}/>;
        // for local debugging
        return <LinkedInBlockMessenger/>
    } else {
        /**
         * L'application sert une route pour chaque bloc intégré dans une iFrame
         */
        return <Router>
            <Switch>

                {/* ------------------------- Route pour le bloc Profil */}
                <Route path="/profileBlock">
                    <LinkedInBlockProfile appContext = {appContext}/>
                </Route>

                {/* ------------------------- Route pour le bloc Recherche */}
                <Route path="/messengerBlock">
                    <LinkedInBlockMessenger/>
                </Route>

                <Route path="/generalBlock">
                    <LinkedInBlock/>
                </Route>

            </Switch>
        </Router>
    }

}
